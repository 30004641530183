import React, { useContext, useEffect, useState } from "react";
import {
    StyleSheet,
    Text,
    View,
    Dimensions,
    Animated,
    Image,
    ActivityIndicator,
    Linking,
    RefreshControl,
    TouchableOpacity,
    ScrollView,
    Modal,
    Alert
} from "react-native";

import AsyncStorage from '@react-native-community/async-storage';
import Constants from 'expo-constants';
import { Entypo } from '@expo/vector-icons';
import Moment from "react-moment";
import firebase from "firebase";
import "firebase/firestore";
import RNAnimatedScrollIndicators from 'react-native-animated-scroll-indicators';
import AxiosCall from "../../utilities/Axios"
import { setStatusBarNetworkActivityIndicatorVisible } from "expo-status-bar";
// import HomeComponent from "../Components/HomeComponent";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;
const cardPerSlide = 1
const cardPadding = 10
const paddingAround = cardPadding * 2 // slide horizontal padding
const cardBetweenPadding = cardPadding * (cardPerSlide - 1)
const totalPadding = paddingAround + cardBetweenPadding
const imageWidth = (SCREEN_WIDTH - totalPadding) / cardPerSlide
const imageHeight = (imageWidth / (2 / 3))
const contentOffset = (SCREEN_WIDTH - (SCREEN_WIDTH * 0.96)) / 2;
const deviceWidth = Dimensions.get('window').width
const FIXED_BAR_WIDTH = 250
const BAR_SPACE = 10
const wait = (timeout) => {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}


const UserScreen = ({ route, navigation }) => {
    const [userID, setUserID] = useState("");
    const [userEmail, setUserEmail] = useState("")
    const [isDriver, setIsDriver] = useState("");
    const [name, setName] = useState("");
    const [orders, setOrders] = useState([])
    const [secretCode, setSecretCode] = useState("")
    const [refreshing, setRefreshing] = useState(false);
    const [times, setTimes] = useState([])
    const [today, setToday] = useState("")
    const [activity, setActivity] = useState(true)
    const [hideFulfilled, setHideFulfilled] = useState(false)
    const [viewStatus, setViewStatus] = useState("All")
    const [filteredOrders, setFilteredOrders] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [generateSecretActivity, setGenerateSecretActivity] = useState(false)
    const [loadingActivity, setLoadingActivity] = useState(false)
    const [testAcct, setTestAcct] = useState(false)

    const onRefresh = React.useCallback(async () => {
        setRefreshing(true);
        await fetchOrders()
        await getTestAcct()
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const DialCall = (phone) => {

        let phoneNumber = '';

        if (Platform.OS === 'android') {
            phoneNumber = `tel:${phone}`;
        }
        else {
            phoneNumber = `telprompt:${phone}`;
        }

        Linking.openURL(phoneNumber);
    };


    const Days = {
        0: "Sun",
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thur",
        5: "Fri",
        6: "Sat",
    };
    const calendarStrings = {
        sameDay: "LT",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "dddd",
        sameElse: "DD-MMM-YY",
    };
    const convertScheduledDate = (time) => {
        if (time == undefined) return
        let a = time.split("/");
        return `${a[1]}/${a[0]}/${a[2]}`;
    };

    const formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const FadeInView = (props) => {
        const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0

        React.useEffect(() => {
            Animated.timing(
                fadeAnim,
                {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                }
            ).start();
        }, [fadeAnim])

        return (
            <Animated.View
                // Special animatable View
                style={{
                    ...props.style,
                    opacity: fadeAnim,         // Bind opacity to animated value
                }}
            >
                {props.children}
            </Animated.View>
        );
    }

    function getFullDate(RST) {
        //console.log('RST is ', RST)
        if (RST == undefined) return
        //let rideDate = new Date(RST * 1000);
        var RIDE_DATE = new Date(RST * 1000);
        var today = new Date()
        //console.log("RST passed is ", RST, " RIDE_DATE is ", RIDE_DATE, ' today is ', today)
        var newDATE = RIDE_DATE.toLocaleDateString(undefined, {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
        // console.log(' full date is ', Days[RIDE_DATE.getDay()] + " " + newDATE)
        return Days[RIDE_DATE.getDay()] + " " + newDATE;
    }

    const displayprice = (price) => {

        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length >= 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }
        //console.log('pString is', pString)
        return pString
    }

    const createChatRequest = async (item) => {
        setLoadingActivity(false)
        try {
            const requestObj = {
                method: "POST",
                path: "createChat",
                data: {
                    idToken: global.idToken,
                    chatID: item.orderID,
                    agentID: global.uid === item.agentID ? global.uid : item.agentID,
                    myUID: global.uid,
                    userID: item.userID === global.uid ? global.uid : item.userID
                },
            };
            const res = await AxiosCall(requestObj);
            if (res.msg === "SUCCESS") {

                setLoadingActivity(false)
                await navigation.navigate("ChattingScreen", {
                    chatID: item.orderID,
                    data: item,
                })
            }
        } catch (err) {
            console.log(err)
        }
    }


    const acceptOrder = async (item) => {
        setLoadingActivity(true)

        try {
            const requestObj = {
                method: "POST",
                path: "updateOrder",
                data: {
                    idToken: global.idToken,
                    userID: item.userID,
                    myUID: global.uid,
                    agentID: global.uid,
                    type: "orderAcceptance",
                    orderID: item.orderID,
                    agentName: global.name,
                    agentPhoneNumber: global.phoneNumber
                },
            };
            const res = await AxiosCall(requestObj);
            if (res.msg === "SUCCESS") {
                await fetchOrders()
            } else if (res.error === "INVALID_REQUEST") {
                Alert.alert("YOu can't accept your own orders")
            }
            setLoadingActivity(false)

        } catch (err) {
            setLoadingActivity(false)
            console.log('Error', err);
        }
    }


    const getTimes = async () => {
        // console.log('firing up... getTimes')
        let times = []
        filteredOrders.forEach(item => {
            var RIDE_DATE = new Date(item.orderRequestTime._seconds * 1000);
            var newDATE = RIDE_DATE.toLocaleDateString(undefined, {
                day: "2-digit",
                month: "short",
                year: "numeric",
            });
            // console.log('item date is ', Days[RIDE_DATE.getDay()] + " " + newDATE)
            let time = Days[RIDE_DATE.getDay()] + " " + newDATE
            times.push(time)
        })
        let uniqueTimes = times.filter((item, index) => {
            return times.indexOf(item) === index
        })
        await setTimes(uniqueTimes)
        //console.log('uniquedates are: ', uniqueTimes,
        //console.log('todays date is', today))
    }
    let numItems = times.length
    let itemWidth = (FIXED_BAR_WIDTH / numItems) - ((numItems - 1) * BAR_SPACE)

    let animVal = new Animated.Value(0)
    let timesArray = []
    let barArray = []
    times.forEach((time, i) => {
        // console.log(time, i)
        const thisOrderSet = (
            <View key={i}>
                <View style={{ marginVertical: 5, padding: 10, marginBottom: 0, paddingBottom: 0 }}>
                    <Text style={{
                        color: "#206bdb",
                        fontFamily: "TTCommons-Regular",
                        fontSize: 20,
                        textAlign: 'left',
                    }}>
                        {time == today ? <Text style={{
                            fontFamily: "TTCommons-ExtraBold", fontWeight: 'bold'
                        }}>Today </Text> : ''} {time}
                    </Text>
                </View>
                {orders.length > 0 &&
                    filteredOrders.map((item, id) => {

                        if (getFullDate(item.orderRequestTime._seconds) !== time) return

                        return (
                            <View style={{ marginVertical: 10, width: SCREEN_WIDTH * 0.9, }} key={id}>
                                <View
                                    style={{
                                        width: SCREEN_WIDTH * 0.88,
                                        minHeight: SCREEN_HEIGHT * 0.1,
                                        backgroundColor: item.status === 'Pending' ? '#ff7dab' : item.status === 'Delivered' ? "#70db70" : "#206bdb",
                                        borderRadius: 12,
                                        padding: 20,
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: SCREEN_WIDTH * 0.74,
                                            alignItems: "flex-start",
                                        }}
                                    >

                                        <View
                                            style={{
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: item.status === 'Pending' ? "#ffe1eb" : "white",
                                                    fontFamily: "TTCommons-Regular",
                                                    fontSize: 14,
                                                }}
                                            >
                                                {getFullDate(item.orderRequestTime._seconds)}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: item.status === 'Pending' ? "#ffe1eb" : "white",
                                                    fontSize: 12,
                                                    marginTop: -6,
                                                    marginLeft: 5
                                                }}
                                            >
                                                (<Moment
                                                    fromNow
                                                    element={Text}
                                                    calendar={calendarStrings}
                                                >
                                                    {item.orderRequestTime._seconds * 1000}
                                                </Moment>)
                                                </Text>
                                        </View>
                                        <Text
                                            style={{
                                                color: item.status === 'Pending' ? "#ffe1eb" : "white",
                                                fontFamily: "TTCommons-Regular",
                                                fontSize: 14,
                                            }}
                                        >
                                            {item.status}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            width: SCREEN_WIDTH * 0.8,
                                            //backgroundColor: "#D7F0FD",
                                            //backgroundColor:'#effbef',
                                            backgroundColor: item.status === 'Pending' ? '#fbf4f6' : item.status === 'Delivered' ? "#ebffeb" : "#eaf1fc",
                                            padding: 20, margin: 10, marginBottom: 0,
                                            borderRadius: 12, flexDirection: "row",
                                            alignItems: "flex-start", justifyContent: "space-between",
                                        }}
                                    >
                                        <View
                                            style={{
                                                flexDirection: "column",
                                                width: "40%",
                                                justifyContent: "space-between",
                                                marginLeft: -10,
                                            }}
                                        >
                                            <View style={{
                                                //backgroundColor: 'white', 
                                                padding: 5, borderRadius: 3
                                            }}>
                                                <Text style={{
                                                    //color: "#206bdb", 
                                                    color: item.status === 'Pending' ? '#e1004f' : item.status === 'Delivered' ? "#33cb33" : "#206bdb",
                                                    paddingTop: 5, fontFamily: "TTCommons-Medium", fontSize: 20
                                                }}>
                                                    N{formatNumber(item.orderAmount)}
                                                </Text>

                                            </View>

                                        </View>

                                        <View
                                            style={{
                                                flexDirection: "column",
                                                width: "60%",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <TouchableOpacity onPress={() => DialCall(item.phoneNumber)} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 5, paddingLeft: 0 }}>
                                                <Text style={{
                                                    //color: "#203866", 
                                                    color: item.status === 'Pending' ? '#e1004f' : item.status === 'Delivered' ? "#33cb33" : "#206bdb",
                                                    fontFamily: "TTCommons-Regular", fontSize: 14, marginTop: 5, fontWeight: 'bold'
                                                }}>
                                                    {item.name || "name : n/a"}
                                                </Text>
                                                <Text numberOfLines={1} style={{
                                                    //color: "#203866", 
                                                    color: item.status === 'Pending' ? '#e1004f' : item.status === 'Delivered' ? "#33cb33" : "#206bdb",
                                                    fontSize: 13, flex: 1
                                                }}>
                                                    {item.phoneNumber || "phone : n/a"}
                                                </Text>
                                            </TouchableOpacity>

                                            <Text style={{
                                                //color: "#203866", 
                                                color: item.status === 'Pending' ? '#e1004f' : item.status === 'Delivered' ? "#33cb33" : "#206bdb",
                                                paddingTop: 5, fontSize: 13
                                            }}>
                                                {item.address || "address : n/a"}
                                            </Text>
                                            <Text style={{
                                                //color: "#203866", 
                                                color: item.status === 'Pending' ? '#e1004f' : item.status === 'Delivered' ? "#33cb33" : "#206bdb",
                                                paddingVertical: 5, fontSize: 13
                                            }}>
                                                {item.area || "area : n/a"}
                                            </Text>

                                            <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                {item.orderAccepted === true ?
                                                    <TouchableOpacity style={{
                                                        //backgroundColor: '#206bdb',  
                                                        backgroundColor: item.status === 'Pending' ? '#e1004f' : item.status === 'Delivered' ? "#33cb33" : "#206bdb",
                                                        borderRadius: 5, padding: 4
                                                    }}
                                                        onPress={async () => {
                                                            await createChatRequest(item)
                                                        }}><Text style={{ fontFamily: "TTCommons-Regular", color: 'white', paddingHorizontal: 5, paddingTop: 5 }}>Open Chat</Text>
                                                    </TouchableOpacity>
                                                    : <TouchableOpacity
                                                        style={{
                                                            //backgroundColor: '#206bdb',  
                                                            backgroundColor: item.status === 'Pending' ? '#e1004f' : item.status === 'Delivered' ? "#33cb33" : "#206bdb",
                                                            borderRadius: 5, padding: 4, paddingTop: 5
                                                        }}
                                                        onPress={async () => {
                                                            await acceptOrder(item)
                                                        }}>
                                                        <Text style={{
                                                            fontFamily: "TTCommons-Regular", color: 'white',
                                                            paddingHorizontal: 5, paddingTop: 5
                                                        }}>Accept </Text>
                                                    </TouchableOpacity>}
                                                <TouchableOpacity style={{
                                                    //backgroundColor: '#206bdb',  
                                                    backgroundColor: item.status === 'Pending' ? '#e1004f' : item.status === 'Delivered' ? "#33cb33" : "#206bdb",
                                                    borderRadius: 5, padding: 4, marginLeft: 5, paddingTop: 5
                                                }} onPress={async () => {
                                                    await navigation.navigate("OrderDetails", {
                                                        itemId: item.orderID,
                                                        data: item,
                                                    });
                                                }}><Text style={{ fontFamily: "TTCommons-Regular", color: 'white', paddingHorizontal: 5, paddingTop: 5 }}>Details </Text>
                                                </TouchableOpacity>
                                            </View>

                                        </View>

                                    </View>

                                </View>
                            </View>
                        )
                    }
                    )
                }
            </View >
        )
        timesArray.push(thisOrderSet)

        const scrollBarVal = animVal.interpolate({
            inputRange: [deviceWidth * (i - 1), deviceWidth * (i + 1)],
            outputRange: [-itemWidth, itemWidth],
            extrapolate: 'clamp',
        })

        const thisBar = (
            <View
                key={`time${i}`}
                style={[
                    styles.track,
                    {
                        width: itemWidth,
                        marginLeft: i === 0 ? 0 : BAR_SPACE,
                    },
                ]}
            >
                <Animated.View

                    style={[
                        styles.bar,
                        {
                            width: itemWidth,
                            transform: [
                                { translateX: scrollBarVal },
                            ],
                        },
                    ]}
                />
            </View>
        )
        barArray.push(thisBar)
    })

    const fetchOrders = async () => {
        try {
            const requestObj = {
                method: "POST",
                path: "getOrders",
                data: {
                    idToken: global.idToken,
                    userID: global.uid,
                    role: global.isAgent ? "admin" : "user",
                    type: hideFulfilled ? "fulfilledOrders" : ""
                },
            };
            console.log("This is coming from Orders... ");
            const res = await AxiosCall(requestObj);
            if (res.msg === "SUCCESS") {
                const ORDERS = res.data
                let sortedOrders = ORDERS.sort((a, b) => {
                    return b.orderRequestTime._seconds - a.orderRequestTime._seconds;
                });
                let b = []
                let c = {}
                ORDERS.forEach(item => {
                    c[item.orderID] = false
                    b.push(c)
                })
                await setGenerateSecretActivity(b)
                await setOrders(sortedOrders);
                AsyncStorage.setItem("orders", JSON.stringify(sortedOrders || []));
                filterOrders()
            }
        } catch (err) {
            console.log(err);
        }
    }

    const filterOrders = async () => {
        if (orders.length == 0) return
        await setLoadingActivity(true)
        let ORDERS = orders
        let filtered = orders
        if (viewStatus === 'All') {
            setFilteredOrders(orders)
        }
        else if (viewStatus === 'Delivered') {
            filtered = orders.filter(item => {
                if (item.status === 'Delivered') return true
            })
        }
        else if (viewStatus === 'In Progress') {
            filtered = orders.filter(item => {
                if (item.status === 'In Progress') return true
            })
        }
        else if (viewStatus === 'Pending') {
            filtered = orders.filter(item => {
                if (item.status === 'Pending') return true
            })
        }
        // console.log('filtered are: ', filteredOrders, ' orders are: ', orders)
        setLoadingActivity(false)
        setFilteredOrders(filtered)
    }

    const generateSecretCode = async (orderID) => {
        let d = generateSecretActivity
        d[orderID] = true
        setGenerateSecretActivity(d)
        try {
            const code = Math.floor(Math.random() * 899999 + 100000);
            setSecretCode(code)
            const requestObj = {
                method: "POST",
                path: "updateOrder",
                data: { idToken: global.idToken, myUID: global.uid, type: "secretCode", code, orderID },
            };
            const res = await AxiosCall(requestObj);
            if (res.msg === "SUCCESS") {
                d[orderID] = false
                setGenerateSecretActivity(d)
                await fetchOrders()
            }
        } catch (err) {
            console.log('Error', err);
        }
        d[orderID] = false
        setGenerateSecretActivity(d)
    }

    useEffect(() => {
        //getTestAcct()

        var Today = new Date()
        var newToday = Today.toLocaleDateString(undefined, {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
        var TodaysDate = Days[Today.getDay()] + " " + newToday
        setToday(TodaysDate)
        getTimes()
        fetchOrders()
    }, [orders])

    useEffect(() => {
        if (orders.length <= 0) {
            getTestAcct()
        }

    }, [])

    const getTestAcct = async () => {
        const result = await AsyncStorage.getItem("testAcct");
        console.log('testacct in storage is ', result)
        if (result == null) { return }
        if (result == "+98765432100") {
            setTestAcct(true)
        } else {
            setTestAcct(false)
        }

    }


    let fulfilledArray = []
    let pendingArray = []
    let inProgress = []
    orders.forEach((order) => {
        if (order.status === 'Delivered') {
            fulfilledArray.push(order)
        }
        if (order.status === 'Pending') {
            pendingArray.push(order)
        }
        if (order.status === 'In Progress') {
            inProgress.push(order)
        }
    })

    return (

        <View
            style={{
                backgroundColor: "#f7fbfd",
                height: SCREEN_HEIGHT,
                width: SCREEN_WIDTH,
                alignItems: "center",
                flex: 1, justifyContent: 'center',
                paddingTop: 10
            }}
        >

            {Array.isArray(orders) && (orders.length == 0) && (!testAcct) && <View style={{ marginTop: 60, width: SCREEN_WIDTH * 0.8 }}>
                <Text style={{ textAlign: 'center', fontSize: 16, color: "#206bdb", marginTop: 20 }}>
                    You currently have no orders. Once you place an order, you will be able to view it here. </Text>
            </View>}

            <ScrollView
                style={{
                    display: "flex",
                    width: "100%",
                    paddingTop: 50,
                    padding: 20,
                    height: "100%",
                    position: "relative",
                }}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
            > 
                {/* ///////////////////////////// ORDERS VIEW FOR CUSTOMERS ///////////////////////////////*/}

                {(!global.isAgent && !global.isAdmin) && <View style={{ alignSelf: 'center', marginTop: 20 }}>

                    {orders.length > 0 &&
                        orders.map((item, id) => {
                            // console.log('delivery status for ', getFullDate(item.orderRequestTime._seconds), ' is ', item.status)

                            return (
                                <View style={{ margin: 10, opacity: item.status == 'Delivered' ? 0.5 : 1 }} key={id}>
                                    <View
                                        style={{
                                            width: SCREEN_WIDTH * 0.88,
                                            minHeight: SCREEN_HEIGHT * 0.1,
                                            backgroundColor: "#206bdb",
                                            borderRadius: 12,
                                            padding: 20,
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                width: SCREEN_WIDTH * 0.76,
                                                alignItems: "flex-start",
                                            }}
                                        >

                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: "white",
                                                        fontFamily: "TTCommons-Regular",
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    {getFullDate(item.orderRequestTime._seconds)}
                                                </Text>
                                                <Text
                                                    style={{
                                                        color: "#cceeff",
                                                        fontSize: 12,
                                                        marginTop: -6,
                                                        marginLeft: 5
                                                    }}
                                                >
                                                    (<Moment
                                                        fromNow
                                                        element={Text}
                                                        calendar={calendarStrings}
                                                    >
                                                        {item.orderRequestTime._seconds * 1000}
                                                    </Moment>)
                                                </Text>
                                            </View>
                                            <Text
                                                style={{
                                                    color: "white",
                                                    fontFamily: "TTCommons-Regular",
                                                    fontSize: 13,
                                                }}
                                            >
                                                {item.status}
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                width: SCREEN_WIDTH * 0.83, backgroundColor: "#D7F0FD",
                                                padding: 20, margin: 10, marginBottom: 0,
                                                borderRadius: 12, flexDirection: "row",
                                                alignItems: "flex-start", justifyContent: "space-between",
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flexDirection: "column",
                                                    width: "45%",
                                                    justifyContent: "space-between",
                                                    marginLeft: -10,
                                                }}
                                            >
                                                <View style={{ backgroundColor: 'white', padding: 5, borderRadius: 10, paddingLeft: 8 }}>
                                                    <Text style={{ color: "#206bdb", paddingTop: 5, fontFamily: "TTCommons-Medium", fontSize: 22 }}>
                                                        N{formatNumber(item.orderAmount)}
                                                    </Text>
                                                    <View style={{
                                                        flexDirection: 'row', justifyContent: 'flex-start', marginTop: -5, borderBottomColor: 'black',
                                                        borderBottomWidth: 0.4, width: '85%', marginBottom: 5
                                                    }}>
                                                        <Text style={{ color: "#206bdb", fontSize: 10, marginBottom: 5 }}>
                                                            {"R: "}{item.rate}
                                                        </Text>
                                                        <Text style={{ color: "#206bdb", fontSize: 10, marginBottom: 5, marginLeft: 5 }}>
                                                            {"CF: "}{displayprice(item.deliveryCharge)}
                                                        </Text>
                                                    </View>
                                                    <Text style={{ color: 'black', fontFamily: "TTCommons-Thin" }} >secret code:</Text>
                                                    <View
                                                        style={{
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            flexDirection: 'row',
                                                            //backgroundColor: "#FFF",
                                                            padding: 0,
                                                            borderRadius: 5,
                                                            margin: 0,
                                                            justifyContent: 'flex-start'
                                                        }}
                                                    >
                                                        <Text style={{ fontFamily: "TTCommons-Thin", fontSize: 25, textAlign: 'left' }}>{item.secretCode}</Text>
                                                        {item.status !== 'Delivered' && !generateSecretActivity[item.orderID] && <TouchableOpacity style={{ backgroundColor: '#D3D3D3', justifyContent: 'flex-start', padding: 5, borderRadius: 3, marginLeft: 5, marginBottom: 7 }}
                                                            onPress={() => { generateSecretCode(item.orderID) }}>

                                                            <Image
                                                                style={{ width: 15, height: 15 }}
                                                                source={{
                                                                    uri: 'https://firebasestorage.googleapis.com/v0/b/order-cash-ee263.appspot.com/o/reload.png?alt=media&token=85603151-6792-4559-8ddc-738fe92ad72e',
                                                                }}
                                                            />
                                                        </TouchableOpacity>}
                                                        {generateSecretActivity.hasOwnProperty(item.orderID) && generateSecretActivity[item.orderID] && <ActivityIndicator
                                                            size="small"
                                                            color="#206bdb"
                                                            style={{
                                                                opacity: 1
                                                            }}
                                                            animating={true}
                                                        />}
                                                    </View>
                                                </View>
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: "column",
                                                    width: "55%",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                                                    <Text style={{ color: "#203866", fontFamily: "TTCommons-Regular", fontSize: 14, marginTop: 5 }}>
                                                        {item.name || "name : n/a"}
                                                    </Text>
                                                    <Text style={{ color: "#203866", fontSize: 14, }}>
                                                        {item.phoneNumber || "phone : n/a"}
                                                    </Text>
                                                </View> */}

                                                <Text style={{ color: "#203866", paddingTop: 5, fontSize: 13 }}>
                                                    {item.address || "address : n/a"}
                                                </Text>
                                                <Text style={{ color: "#203866", paddingVertical: 5, fontSize: 13 }}>
                                                    {item.area || "area : n/a"}
                                                </Text>
                                                <Text style={{ color: "#206bdb", fontSize: 12, marginBottom: 8 }}>
                                                    {item.status == "Delivered" ? `Delivered by ${item.agentName}` : item.status == "In Progress" ? `Assigned to ${item.agentName}` : "Thank you for your order, an agent will fulfill your order shortly"}
                                                </Text>

                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    {item.status === 'In Progress' && <View>
                                                        <TouchableOpacity style={{ backgroundColor: '#206bdb', borderRadius: 5, padding: 5, marginBottom: 5 }}
                                                            onPress={async () => {
                                                                await createChatRequest(item)
                                                            }}
                                                        >
                                                            <Text style={{ fontFamily: "TTCommons-Regular", fontSize: 14, color: 'white', paddingHorizontal: 20, paddingTop: 5 }}>Open Chat</Text>
                                                        </TouchableOpacity>

                                                    </View>}
                                                </View>

                                            </View>

                                        </View>

                                    </View>


                                </View>
                            )
                        }
                        )}
                </View>}
                {orders.length > 0 && <Text style={{ textAlign: 'center', fontSize: 12, color: "#206bdb", marginTop: 20 }}>*Please provide your <Text style={{ fontWeight: 'bold' }}>SECRET CODE</Text> to the assigned agent to fulfill your order.
                Refresh if compromised.
                </Text>}
                <View style={{ height: 400 }}>

                </View>
                {/* <Modal
                    animationType="slide"
                    transparent={true}
                    visible={loadingActivity}
                >
                    <View style={{
                        position: "relative", bottom: 0, left: 0, height: "100%", width: SCREEN_WIDTH, flex: 1,
                        alignItems: "center", backgroundColor: "rgba(255,255,255,0.7)", alignItems:'center', justifyContent:'center',
                        padding: 20, alignSelf: "center", borderTopEndRadius: 20
                    }}>
                        <ActivityIndicator
                            size="large"
                            color="#206bdb"
                            style={{
                                opacity: 1
                            }}
                            animating={loadingActivity}
                        />
                        <Text style={{ textAlign: 'center', fontSize: 12, color: "#206bdb", marginTop: 20 }}> Please wait ...</Text>
                    </View>
                </Modal> */}
            </ScrollView>
            {/* <View style={{color: "#206bdb",}}>
                <Entypo name="chat" size={30} color="#206bdb" />
            </View> */}
        </View>
    );
};
export default UserScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: '#FFFFFF',
        height: '100%'
    },
    navBar: {
        width: '100%',
        height: 70,
        backgroundColor: '#0D0D0D',
        alignContent: 'center',
        justifyContent: 'center'
    },
    navBarTitle: {
        color: 'white',
        fontSize: 16,
        fontWeight: "bold",
        alignSelf: 'center'
    },
    scrollViewContainerStyle: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        height: 600,
        //width:'100%'
    },
    barContainer: {
        //position: 'absolute',
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
        //top: 40,
        flexDirection: 'row',
    },
    track: {
        backgroundColor: '#ccc',
        overflow: 'hidden',
        height: 2,
    },
    bar: {
        backgroundColor: '#5294d6',
        height: 2,
        position: 'absolute',
        left: 0,
        top: 0,
    },
});