import React, { useState, useEffect, useContext } from "react";
import { AsyncStorage, Text, View, } from "react-native";
import { Context as AuthContext } from "../context/AuthContext";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { Ionicons, SimpleLineIcons } from '@expo/vector-icons';
import Home from "../Screens/Main/HomeScreen";
import Settings from "../Screens/Main/SettingsScreen";
import OrderDetails from "../Screens/Main/OrderDetails";
import Orders from "../Screens/Main/Orders";
import ChatScreen from "../Screens/Main/ChatScreen"
import ChattingScreen from "../Screens/Main/ChattingScreen";

const HomeStack = createStackNavigator();
function HomeStackScreen() {
  return (
    <HomeStack.Navigator>
      <Tab.Screen name="Home" component={Home} options={{ headerShown: false }} />
      <Tab.Screen name="Settings" component={Settings} options={{ headerShown: false }} />
    </HomeStack.Navigator>
  );
}

const Stack = createStackNavigator();
function OrderStackScreen({ navigation }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Orders" component={Orders} options={{ headerShown: false }} />
      <Stack.Screen name="ChatScreen" component={ChatScreen} />
      <Stack.Screen name="ChattingScreen" component={ChattingScreen} options={{
          title: 'Chat',/* 
          headerStyle: {
            backgroundColor: '#f4511e',
          }, */
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontWeight: 'bold',
          },
        }}/>
      <Stack.Screen name="OrderDetails" options={{ title: "Order Details" }} component={OrderDetails} />
    </Stack.Navigator>
  );
}

const Tab = createBottomTabNavigator();
const Navigation = () => {
  const { state } = useContext(AuthContext);
  const [OrdersId, setOrdersId] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(false);
  return (
    <NavigationContainer>
      <Tab.Navigator
        screenOptions={({ route }) => ({
          
          tabBarIcon: ({ focused, color, size }) => {
            let iconName;
            if (route.name === 'Home') {
              iconName = focused
                ? 'ios-home'
                : 'ios-home';
            } else if (route.name === 'Settings') {
              iconName = focused
                ? 'ios-settings'
                : 'ios-settings';
            }
            else if (route.name === 'Orders') {
              iconName = focused
                ? 'ios-list'
                : 'ios-list';
            }

            return <Ionicons name={iconName} size={size} color={color} />;
          },
          tabBarLabel: ({ focused, tintColor }) => { 
             
              return (
                <Text /> 
              );
          } 
        })}
        tabBarOptions={{
          activeTintColor: '#206bdb',
          inactiveTintColor: 'gray',
        }}
      >
        <Tab.Screen name="Home" component={HomeStackScreen} />
        <Tab.Screen name="Orders" component={OrderStackScreen} />
        <Tab.Screen name="Settings" component={Settings} />
      </Tab.Navigator>
    </NavigationContainer>
  );
};

export default Navigation;
