import React, { useState, useContext, useEffect, useRef } from "react";
import {
  StyleSheet,
  Image,
  Text,
  View,
  Dimensions,
  TextInput,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Linking,
  Animated,
  Keyboard
} from "react-native";
//import KeyboardSpacer from 'react-native-keyboard-spacer';
import firebase from "firebase";
import AxiosCall from "../../utilities/Axios"
import AsyncStorage from '@react-native-community/async-storage';
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;
let previousDate = ''
let currentDate = ''
let alreadyDisplayedChatDates = {}

const MONTHS = {
  "1": "January",
  "2": "February",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December"
}
let a = new Animated.Value(100)
const UserProfile = ({ route, navigation }) => {
  const scrollViewRef = useRef();
  const [messages, setMessages] = useState([])
  const [userID, setUserID] = useState("");
  const [userID1, setUserID1] = useState("")
  const [userID2, setUserID2] = useState("")
  const [userEmail, setUserEmail] = useState("");
  const [endHeight, setEndHeight] = useState(160)
  const [opponentID, setOpponentID] = useState("")
  const [opponentName, setOpponentName] = useState("")
  const [indicator, setIndicator] = useState(false);
  const [text, setText] = useState("")
  const [keyboardHeight, setKeyboardHeight] = useState(a)
  //console.log('keyboard initial value is ', keyboardHeight)
  const [HEIGHT, setHEIGHT] = useState(100)
  const [chatID, setChatID] = useState("")
  const [chatDates, setChatDates] = useState({})
  const [chatDatesArray, setChatDatesArray] = useState([])
  const [messageSending, setMessageSending] = useState(false)
  const [previous, setPrevious] = useState('')
  const [current, setCurrent] = useState('')
  const [orderData, setOrderData] = useState({})

  const keyboardWillShow = (event) => {
    setEndHeight(80 + event.endCoordinates.height)
    Animated.parallel([
      Animated.timing(keyboardHeight, {
        duration: event.duration,
        toValue: event.endCoordinates.height,
        useNativeDriver: true
      })
    ]).start();
    setHEIGHT(event.endCoordinates.height)
    // console.log('keyboard in keyboardWillShow is ', keyboardHeight)
  };

  const keyboardWillHide = (event) => {
    setEndHeight(160)
    Animated.parallel([
      Animated.timing(keyboardHeight, {
        duration: event.duration,
        toValue: 0,
        useNativeDriver: true
      })
    ]).start();
    setHEIGHT(100)
    // console.log('keyboard in keyboardWillHide is ', keyboardHeight)
  };

  const getChats = async () => {
    // await checkNetworkConnection()
    // if (!state.connected) {
    //   return
    // }
    try {
      if (chatID) {
        await firebase
          .firestore()
          .collection('Chats')
          .doc(chatID)
          .get()
          .then((query) => {
            const chatDoc = query.data()
            if (chatDoc) {
              setMessages(chatDoc.chatMessages)
            }
            getChatDates()
          })
          .catch((err) => {
            console.log("An error ocurred fetching chats...", err)
          })
      }
    } catch (err) {
      console.error(err)
    }
  }
  const getChatDates = () => {
    if (messages.length > 0) {
      let times = []
      messages.forEach(item => {

        const milliseconds = item.timeSent.seconds * 1000 + item.timeSent.nanoseconds / 1000000
        const timeCreated = new Date(milliseconds)
        let dDate = MONTHS[timeCreated.getMonth() + 1] + ' ' + timeCreated.getDate().toString() + ' ' + timeCreated.getFullYear().toString()
        times.push(dDate)
        if (!chatDates.hasOwnProperty(dDate)) {
          alreadyDisplayedChatDates[dDate] = item.timeSent.seconds
          setChatDates(alreadyDisplayedChatDates)
        }
      })

      let uniqueTimes = times.filter((item, index) => {
        return times.indexOf(item) === index
      })

      // console.log('chatDates are: ', uniqueTimes)
      setChatDatesArray(uniqueTimes)
    }
  }
  const DialCall = (phone) => {

    let phoneNumber = '';

    if (Platform.OS === 'android') {
      phoneNumber = `tel:${phone}`;
    }
    else {
      phoneNumber = `telprompt:${phone}`;
    }

    Linking.openURL(phoneNumber);
  };

  const send = async () => {

    try {
      if (!text || !text.trim()) { return }
      if (messageSending) { return }
      let messageToBeSent = text
      setText('')
      setMessageSending(true)
      const messageData = {}

      messageData['text'] = messageToBeSent
      messageData['author'] = global.uid
      const chatData = {
        idToken: global.idToken,
        chatID,
        userID: global.uid,
        messageData
      }
      const requestObj = {
        method: "POST",
        path: "sendChat",
        data: chatData,
      };
      const res = await AxiosCall(requestObj);
      // console.log('ksbdjhsbvd,fabs', res);
      await getChatDates()
      await setMessageSending(false)
      if (res.msg === 'SUCCESS') {
        setText('')
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (route.params !== undefined) {
      setChatID(route.params.hasOwnProperty('chatID') ? route.params.chatID : "")
      setOrderData(route.params.hasOwnProperty('data') ? route.params.data : "")
      getChatDates()
    }
    const keyboardWillShowSub = Keyboard.addListener('keyboardWillShow', keyboardWillShow);
    const keyboardWillHideSub = Keyboard.addListener('keyboardWillHide', keyboardWillHide);

    return () => {
      console.log('Do some cleanup');
      keyboardWillShowSub.remove();
      keyboardWillHideSub.remove();
    }

  }, [route, chatID]);
  // console.log('orderrrrr', orderData);
  const renderChatBubble = (item, id, timeCreated) => {
    return (
      <View id='one-chat-block' key={id} style={{
        backgroundColor: global.uid === item.author ? '#2F4D86' : '#11254A',
        //alignItems: 'center',  
        flexDirection: 'column',
        marginTop: 10, maxWidth: '60%',
        borderRadius: 20, padding: 10, paddingHorizontal: 15,
        alignSelf: global.uid === item.author ? 'flex-end' : 'flex-start'
      }}>
        <Text
          style={{
            fontFamily: "TTCommons-Regular",
            color: "#FCD07F", fontSize: 14, textAlign: "left",
          }}>
          {item.text}
        </Text>
        <Text
          style={{
            fontFamily: "TTCommons-Regular",
            color: '#206bdb', fontSize: 11, marginTop: 2,
            textAlign: global.uid === item.author ? "right" : "left",
          }}>
          {timeCreated.toLocaleTimeString()}
        </Text>

      </View>

    )
  }

  useEffect(() => {
    getChatDates()
  }, [messages.length])

  // console.log('MEssages', messages);
  useEffect(() => {
    //scrollTo()
    // getSignInData()
    getChats()
    getChatDates()
  }, [global.idToken, messages, chatID, text,])

  useEffect(() => {
    //orkConnection();
  }, []);
  // console.log('messages', opponentName);
  return (
    <Animated.View
      style={{
        backgroundColor: "#f7fbfd",
        height: SCREEN_HEIGHT,
        width: SCREEN_WIDTH,
        alignItems: "center",
        position: 'relative',
        //paddingBottom: HEIGHT
        //paddingBottom: keyboardHeight 
      }}
    >
      <View id='chat-name-block' style={{
        flexDirection: 'row', justifyContent: 'space-evenly', paddingHorizontal: 20, backgroundColor: '#f7fbfd',
        width: '100%', alignItems: 'center', height: 80, marginTop: 0, shadowOffset: { width: 10, height: 10, },
        shadowColor: 'black',
        shadowOpacity: 0.05,
      }}>
        <TouchableOpacity style={{ width: '100%', paddingRight: 10 }}
          onPress={() => { DialCall(global.uid === orderData.userID ? orderData.agentPhoneNumber : orderData.phoneNumber) }}>
          <Text style={{
            fontFamily: "TTCommons-Regular", fontWeight: 'bold',
            color: "#206bdb", fontSize: 15, textAlign: "left",
          }}
            numberOfLines={1}>
            {global.uid === orderData.userID ? orderData.agentName : orderData.name}
          </Text>
          <Text style={{
            fontFamily: "TTCommons-Regular",
            color: "#206bdb", fontSize: 15, textAlign: "left",
          }} >
            {global.uid === orderData.userID ? orderData.agentPhoneNumber : orderData.phoneNumber + '. Call'}
          </Text>
        </TouchableOpacity>
      </View>

      <ScrollView ref={scrollViewRef}
        onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}
        contentContainerStyle={{ alignItems: "center", width: SCREEN_WIDTH, padding: 20, paddingTop: 0 }}>
        {chatDatesArray.length > 0 && chatDatesArray.map((cDate, id) => {
          return (
            <View key={id} style={{ width: '100%' }}>
              <View >
                <View style={{ marginVertical: 10 }}>
                  <Text style={{
                    fontFamily: "TTCommons-Regular",
                    color: '#206bdb', fontSize: 11, marginTop: 2,
                    textAlign: "center",
                  }}>{cDate}</Text>
                </View>
              </View>

              {messages && messages.map((item, id) => {
                const milliseconds = item.timeSent.seconds * 1000 + item.timeSent.nanoseconds / 1000000
                const timeCreated = new Date(milliseconds)
                let dDate = MONTHS[timeCreated.getMonth() + 1] + ' ' + timeCreated.getDate().toString() + ' ' + timeCreated.getFullYear().toString()
                if (cDate !== dDate) return

                return (
                  <View style={{ flexDirection: 'column', width: '100%' }} key={id}>
                    <View style={{ flexDirection: 'column', width: '100%' }}>
                      {renderChatBubble(item, id, timeCreated)}
                    </View>
                  </View>
                )
              })}
            </View>
          )
        })}

        <View style={{ alignItems: 'flex-end', width: '100%', marginTop: 10, marginRight: 10 }}>
          {messageSending && <ActivityIndicator
            size="small"
            color="#206bdb"
            style={{
              opacity: 1
            }}
            animating={true}
          />}
        </View>

        <View style={{ height: 200 }}>

        </View>
      </ScrollView>
      <Animated.View style={{
        minHeight: 80, position: 'absolute', bottom: endHeight,
        width: '90%',
        alignSelf: 'center',
        display: 'flex', flexDirection: 'row'
      }}>
        <TextInput
          placeholder={'Type a message...'}
          placeholderTextColor={'#206bdb'}
          value={text}
          onChangeText={text => {
            setText(text)
          }}
          value={text}
          style={{
            backgroundColor: '#2F4D86', width: '85%',
            borderWidth: 1, borderColor: '#11254A',
            borderRadius: 20, height: 60, paddingHorizontal: 20, paddingRight: 35,
            borderTopRightRadius: 0, borderBottomRightRadius: 0,
            shadowOffset: { width: 0, height: 10, },
            shadowColor: 'black', shadowRadius: 7.49,
            elevation: 12,
            shadowOpacity: endHeight > 100 ? 0.3 : 0.1,
            color: 'white'

          }} />
        <TouchableOpacity
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 1,
            marginLeft: 0,
            zIndex: 999,
            width: 40,
            height: 60,
            borderTopEndRadius: 12.5,
            borderBottomEndRadius: 12.5,
            borderLeftWidth: 0.5,
            borderColor: "#667",
            backgroundColor: "#FCD07F"
          }}
          onPress={() => {
            send()
          }}
        >
          <Image
            style={{
              width: 20,
              height: 20,
              marginRight: 1,
              tintColor: "white"
            }}
            source={require("../../../assets/Send.png")}
          />
        </TouchableOpacity>
      </Animated.View>
      <KeyboardSpacer />
    </Animated.View>
  );
};
export default UserProfile;
